

import { mapGetters } from 'vuex';

import BrandLogo from '@/components/svg/BrandLogo';
import BrandTagline from '@/components/svg/BrandTagline';
import PageNavMobile from '@/components/PageNavMobile';

export default {

    name: 'PageNav',

    components: {
        BrandLogo,
        BrandTagline,
        PageNavMobile
    },

    data() {
        return {
            componentName: this.$options.name + ' Component',
            menuBreakpoint: 992
        };
    },

    computed: {
        ...mapGetters(['isAuthenticated'])
    },
    
    created() {

    },
    
    mounted() {
        //window.addEventListener('resize', this.handleWindowResize, false);
        document.addEventListener('click', this.handleDocClick, false);
    },
    
    methods: {

        /**
         * @method handleDocClick
         * @description
         * @param {Object} evt
         */
        handleDocClick(evt) {
            let toggler = document.querySelector('button.navbar-toggler');
            // trigger nav toggle if open
            if (toggler && !toggler.classList.contains('collapsed')) {
                toggler.click();
            }
        },

        /**
         * @method handleWindowResize
         * @description Window resize event handler.
         * @param {Object} evt
         */
        /*handleWindowResize(evt) { // eslint-disable-line no-unused-vars
            let mql = window.matchMedia('(min-width: 992px)');
            let navToggleEl = document.querySelector('.navbar-toggler');

            // check if we match the media query && toggle element expanded attribute is currently true
            if (mql.matches && (navToggleEl && navToggleEl.getAttribute('aria-expanded') === 'true')) {
                navToggleEl.click();
            }
        }*/

    },

    beforeDestroy() {
        document.removeEventListener('click', this.handleDocClick, false);
    }

};

