


export default {

    name: 'PageBlade',

    components: {

    },

    data() {
        return {
            componentName: this.$options.name + ' Component'
        };
    },

    created() {

    },

    mounted() {
        setTimeout(() => {
            this.initBlade();
        }, 5000);
    },

    methods: {
        /**
         * @method initBlade
         * @description Initialize blade timeline animation.
         */
        initBlade() {
            let blade = this.$refs.pageBlade;
            const tl = this.$gsap.timeline({
                scrollTrigger: {
                    trigger: blade,
                    start: 'top 80%',
                    end: 'bottom bottom',
                    scrub: true,
                    markers: false,
                    toggleActions: 'play reverse play reverse'
                }
            });
            tl.from(blade, {
                duration: 1,
                opacity: 0,
                y: '+=180'
            });
        }
    }

};

