// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/blade-pplus-white-grey.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/blade-pplus-white-grey_2x.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#app[data-v-6bc1b432]{font-family:\"PeakSans-Regular\",sans-serif;font-size:1rem;line-height:1.337;text-align:center;color:#1b1c1e;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;position:relative}@media(min-width:768px){#app[data-v-6bc1b432]{font-size:1.125rem;transition:font-size .3s ease-out}}.page-blade[data-v-6bc1b432]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-position:100% 100%;background-repeat:no-repeat;width:100%;height:80vh;position:absolute;right:0;bottom:0;z-index:-1;pointer-events:none}@media only screen and (-webkit-min-device-pixel-ratio:1.25),only screen and (min-device-pixel-ratio:1.25),only screen and (min-resolution:1.25dppx){.page-blade[data-v-6bc1b432]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:contain}}.page-enter-active[data-v-6bc1b432],.page-leave-active[data-v-6bc1b432]{transition:all .4s}.page-enter-from[data-v-6bc1b432],.page-leave-to[data-v-6bc1b432]{opacity:0;filter:blur(1rem)}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
