import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionResult from '../fragmentType.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: introspectionResult
});

export default ({ req, app }) => {
    return {
        httpEndpoint: "https://strapi.weareparamountplus.com/graphql",
        cache: new InMemoryCache({ fragmentMatcher })
    };
};
