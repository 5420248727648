

import { mapGetters } from 'vuex';

import MenuList from '~/apollo/queries/menu/MenuList';

export default {

    name: 'PageNavSidebar',

    props: {
        placement: {
            type: String,
            default: ''
        }
    },
    
    data() {
        return {
            componentName: this.$options.name + ' Component',
            activeClass: 'active',
            scrollOffset: 160,
            scrollDuration: 600,
            highlightFirstItem: true,
            scrollOnStart: true,
            alwaysTrack: true,
            modifyUrl: false,
            exact: false,
            bezierEasingValue: '.5,0,.35,1',
            labels: {
                pageNavLabel: 'Jump to'
            },
            pages: [],
            query: ''
        };
    },

    apollo: {
        pages: {
            prefetch: true,
            query: MenuList
        }
    },

    computed: {
        ...mapGetters(['isAuthenticated', 'loggedInUser']),
        toggleState() {
            return (this.placement === 'navbar') ? 'collapse' : null;
        }
    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {

        /**
         * @method handleNavLinkClick
         * @description Nav link click event handler.
         * @param {Object} evt
         */
        handleNavLinkClick(evt) {
            const activeTOCHash = evt.currentTarget.getAttribute('href');
            const activeTOCId = activeTOCHash.replace('#', '');

            setTimeout(() => {
                location.hash = activeTOCId;
            }, this.scrollDuration);
        },

        /**
         * @method userLogout
         * @description Logout authenticated user.
         * @returns {Promise<void>}
         */
        async userLogout() {
            this.$nuxt.$emit('click::logout', {});
            await this.$auth.logout();
            this.$router.push('/');

            let target = document.querySelector('.page-hero');
            if (target) {
                target.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }

    }
    
};

