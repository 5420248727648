import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6bc1b432&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=6bc1b432&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bc1b432",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHeader: require('/opt/atlassian/pipelines/agent/build/components/PageHeader.vue').default,PageFooter: require('/opt/atlassian/pipelines/agent/build/components/PageFooter.vue').default,PageBlade: require('/opt/atlassian/pipelines/agent/build/components/PageBlade.vue').default})
