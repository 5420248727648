import { render, staticRenderFns } from "./PageNav.vue?vue&type=template&id=743bc27e&scoped=true&"
import script from "./PageNav.vue?vue&type=script&lang=js&"
export * from "./PageNav.vue?vue&type=script&lang=js&"
import style0 from "./PageNav.vue?vue&type=style&index=0&id=743bc27e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "743bc27e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageNavMobile: require('/opt/atlassian/pipelines/agent/build/components/PageNavMobile.vue').default})
