

export default {

    name: 'BrandTagline',
    
    data() {
        return {
            componentName: this.$options.name + ' Component'
        };
    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {
        
    }
    
};

